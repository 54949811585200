<template>
  <div
    class="relative"
    @mouseover="showTooltip"
    @mouseleave="hideTooltip"
    @focus="showTooltip"
    @blur="hideTooltip"
  >
    <slot></slot>
    <transition name="fade">
      <div
        v-if="visible"
        ref="tooltip"
        :class="[
          'absolute w-auto bg-dark top-[-40px] -left-4 text-center margin-auto text-white px-2.5 py-1.5 rounded whitespace-nowrap z-[10000]'
        ]"
        :style="tooltipStyle"
      >
        {{ $props.content }}
        <div :class="['tooltip-arrow', position === 'left' ? 'right-arrow' : '']"></div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
const tooltip = ref(null)

const props = defineProps<{
  content: string
  position: 'top' | 'left' | 'bottom'
}>()

const visible = ref(false)

const showTooltip = () => {
  visible.value = true
  updateTooltipPosition()
}
const tooltipStyle = ref({})
const hideTooltip = () => {
  visible.value = false
}
const updateTooltipPosition = () => {
  if (tooltip.value) {
    const tooltipRect = tooltip?.value?.getBoundingClientRect()
    let top = '-40px'
    let left = '0'
    if (props.position === 'left') {
      top = '0'
      left = `-${tooltipRect.width + 10}px`
    } else if (props.position === 'bottom') {
      top = '40px'
      left = '-10px'
    }

    tooltipStyle.value = {
      top,
      left
    }
  }
}

watch(visible, (newValue) => {
  if (newValue) {
    updateTooltipPosition()
  }
})
onMounted(() => {
  if (tooltip.value) {
    updateTooltipPosition()
  }
})
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  @apply transition-opacity;
}
.fade-enter-from,
.fade-leave-to {
  @apply opacity-0;
}
.tooltip-arrow {
  @apply absolute w-0 h-0 border-solid;
}
.right-arrow {
  border-width: 5px 0 5px 5px;
  border-color: transparent transparent transparent #050505;
  @apply top-1/2 right-[-5px] transform translate-y-[-50%];
}
</style>
