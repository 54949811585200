<template>
  <Tooltip :content="ratingText" :position="props.position">
    <div
      v-if="rating > 0 && rating <= 7"
      :class="{
        'w-10 h-10': true,
        'flex items-center justify-center rounded-full p-2 relative': true,
        'bg-primary-50 text-black': active,
        'bg-[#1e1e1f] text-secondary-text': !active,
        [props.class]: true
      }"
    >
      <span
        :class="{
          'mt-[2px] font-medium': true,
          'text-black': active,
          'text-secondary-text': !active
        }"
        >{{ rating }}</span
      >
      <RatingCircle :svg-class="props.svgClass"></RatingCircle>
    </div>
  </Tooltip>
</template>

<script setup lang="ts">
import Tooltip from '~/components/tooltip.vue'
import RatingCircle from '~/components/icons/rating-circle.vue'
const { t: $t } = useI18n({
  useScope: 'global'
})

// TODO: Make the tooltip works
const ratingItems = ref(
  Array.from(Array(7).keys()).map((i) => ({
    title: `${$t(`shared.ratingNames.${i + 1}`)}`,
    value: i + 1
  }))
)

const ratingText = computed(() => ratingItems.value.find((el) => el.value === props.rating)?.title)
const props = withDefaults(
  defineProps<{
    rating: number
    active?: boolean
    class?: string | string[]
    svgClass?: string
    position?: 'top' | 'left' | 'bottom'
  }>(),
  {
    active: true,
    class: '',
    svgClass: '',
    position: 'left'
  }
)
</script>
