<template>
  <svg
    :class="['absolute inset-1.5 object-contain w-[28px] h-[28px]', props.svgClass]"
    viewBox="0 0 27 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M1 13.9999C1 7.11272 6.52511 1.5293 13.3404 1.5293"
        stroke="currentColor"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M25.6812 14C25.6812 20.8876 20.1561 26.4706 13.3408 26.4706"
        stroke="currentColor"
        stroke-width="2"
        stroke-miterlimit="10"
      />
    </g>
  </svg>
</template>
<script setup lang="ts">
const props = defineProps<{
  svgClass?: string
}>()
</script>

<style scoped></style>
